jQuery(function () {
    const ares_min = {
        name: 5,
        ico: 8,
    };
    const ares_timeout = 600;
    const ARES_API_URL = "https://ares.gov.cz/ekonomicke-subjekty-v-be/rest";

    var timeoutHandle = window.setTimeout(handleAres, 1000);
    var prefix = "";
    var postfix = "";
    window.clearTimeout(timeoutHandle);

    $("body").on(
        "input",
        'input[name^="firma_nazev"], input[name^="firma_ico"], input[name^="firma"], input[name^="ico"]',
        handlePreAres
    );
    //$("body").on("input", 'input[name^="firma_ico"], input[name^="ico"]', handlePreAres);

    function handlePreAres(e) {
        let el = $(e.target);
        const val = el.val();

        let naseptavac = $("#ares-naseptavac");
        let name = el.attr("name");
        let type_text = name == "firma_nazev" || name == "firma" ? "name" : "ico";
        prefix = name == "firma_nazev" || name == "firma_ico" ? "firma_" : "";
        postfix = name == "firma_nazev" || name == "firma_ico" ? "_firma" : "";
        let minimum = ares_min[type_text];
        naseptavac.hide();

        if (minimum <= val.length) {
            const url = `${ARES_API_URL}/ekonomicke-subjekty`;

            window.clearTimeout(timeoutHandle);
            timeoutHandle = window.setTimeout(handleAres, ares_timeout, url, type_text, val);
        } else {
            window.clearTimeout(timeoutHandle);
        }
    }

    function handleError(code) {
        let message = "Pro Vámi zadané údaje nebyly v systému ARES nalezeny žádné shody.";
        if (code !== 404) {
            message = "Během vyhledávání v systému ARES vznikla chyba. Opakujte prosím později.";
        }

        let naseptavac = $("#ares-naseptavac");
        let naseptavacIn = $("#naseptavac-items");
        naseptavacIn.empty();
        let div = $("<div/>").addClass("naseptavac-item");
        let p1 = $("<p/>").addClass("headline").html("Žádné výsledky");
        let p2 = $("<p/>").html(message);
        div.append(p1);
        div.append(p2);
        naseptavacIn.append(div);

        naseptavac.show();
    }

    function handleAres(url, type, val) {
        const urlFinal = type == "ico" ? `${url}/${val}` : `${url}/vyhledat`;
        const encoded = encodeURI(urlFinal);
        const method = type == "ico" ? "GET" : "POST";
        const urlData =
            type == "ico"
                ? {}
                : {
                      start: 0,
                      pocet: 5,
                      obchodniJmeno: val,
                  };

        let naseptavac = $("#ares-naseptavac");
        let naseptavacIn = $("#naseptavac-items");
        let hidden = naseptavac.attr('data-hidden');

        if (hidden === 'false'){
            naseptavacIn.empty();
            let place = $("<div/>").addClass("naseptavac-item");
            let placeholder1 = $("<p/>").addClass("naseptavac-placeholder col-5");
            let placeholder2 = $("<p/>").addClass("naseptavac-placeholder col-9");
            place.append(placeholder1);
            place.append(placeholder2);
            naseptavacIn.append(place);
            naseptavac.show();

            $.ajax({
                url: encoded,
                method: method,
                contentType: "application/json;charset=utf-8",
                dataType: "json",
                data: JSON.stringify(urlData),
                success: function (data) {
                    const realData = type == "ico" ? [data] : data.ekonomickeSubjekty;
                    naseptavacIn.empty();

                    if (realData.length > 0) {
                        realData.forEach((e) => {
                            var div = $("<div/>")
                                .addClass("naseptavac-item")
                                .attr("data-ares-name", e.obchodniJmeno)
                                .attr("data-ares-ico", e.ico)
                                .attr("data-ares-dic", e.dic)
                                .attr("data-ares-address", e.sidlo.nazevUlice + " " + e.sidlo.cisloDomovni)
                                .attr("data-ares-obec", e.sidlo.nazevObce)
                                .attr("data-ares-psc", e.sidlo.psc);
                            var p1 = $("<p/>")
                                .addClass("headline")
                                .html(e.obchodniJmeno + ", IČ: " + e.ico);
                            if (e.dic) {
                                p1.append(", DIČ: " + e.dic);
                            }
                            var p2 = $("<p/>").html(e.sidlo.textovaAdresa);

                            div.append(p1);
                            div.append(p2);

                            naseptavacIn.append(div);
                        });
                    } else {
                        handleError(404);
                    }
                },
                error: function (xhr, textStatus) {
                    const code = xhr.status;
                    handleError(code);
                },
            });
        } else  {
            naseptavac.hide();
        }
    }

    $("body").on("click", "div.naseptavac-item", function () {
        let naseptavac = $("#ares-naseptavac");
        let name = $(this).attr("data-ares-name");
        let ico = $(this).attr("data-ares-ico");
        let dic = $(this).attr("data-ares-dic");
        let firmaSelector = prefix === "" ? "firma" : "firma_nazev";

        if (name) {
            $('input[name="' + firmaSelector + '"]').val(name);
            $('input[name="' + prefix + 'ico"]').val(ico);
            $('input[name="' + prefix + 'dic"]').val(dic);

            $('input[name="ulice' + postfix + '"]').val($(this).attr("data-ares-address"));
            $('input[name="obec' + postfix + '"]').val($(this).attr("data-ares-obec"));
            $('input[name="psc' + postfix + '"]').val($(this).attr("data-ares-psc"));
        }

        naseptavac.hide();
    });

    $("body").on("click", "#ares-naseptavac-close", function () {
        let naseptavac = $("#ares-naseptavac");
        naseptavac.hide();
    });
});
