/**
 * Global function for registering Seznam's "naseptavac" to input element.
 * @param {Array.<string>} e - Main input element (with searching address)
 * @param {string} u - Address input element which should be updated by SMap suggest
 * @param {string} o - City input element ..
 * @param {string} p - Postal code input element ..
 * @param {string} k - Postal code input element ..
 */
function registerNaseptavac(e, u, o, p, k) {
    e.forEach((inpt) => {
        $(inpt).autocomplete({
            delay: 500,
            classes: {
                "ui-autocomplete": "address-suggest"
            },
            source: (_val, response) => {
                const query = e.reduce((acc, el) => acc + (document.querySelector(el).value ? document.querySelector(el).value + "," : ""), "");

                $.nette.ajax({
                    type: "POST",
                    url: "?do=suggestAddress",
                    off: ['modals'],
                    data: {
                        query: query
                    },
                    success: (data) => {
                        if(data.items && data.items.length > 0) {
                            const result = data.items.map((address) => {
                                return {
                                    value: `${address.regionalStructure[1].name} ${address.regionalStructure[0].name}`,
                                    label: `${address.regionalStructure[3].name}${address.regionalStructure[2].name && address.regionalStructure[2].name.length > 0 ? ` - ${address.regionalStructure[2].name}` : ""}`,
                                    postal: `${address.zip}`,
                                    region: `${findIdByKrajNameLike(address.regionalStructure)}`
                                };
                            });

                            response(result);
                            return;
                        }
                        response([]);
                    }
                });
            },
            select: function( event, ui ) {
                document.querySelector(o).value = ui.item.label;
                document.querySelector(u).value = ui.item.value;
                document.querySelector(p).value = ui.item.postal;
                document.querySelector(k).value = ui.item.region;

                return false;
            }
        }).autocomplete("instance")._renderItem = function(ul, item) {
            return $(`<li>`)
                .append(`<div><span class="font-weight-bold">${item.value}</span><br>${item.postal} ${item.label}</div>`)
                .appendTo( ul );
        };
    });
}

function findIdByKrajNameLike(regionalStructure) {
    let kraje = [
        [197, 'CZ064', 'Jihomoravský kraj'],
        [205, 'CZ063', 'Kraj Vysočina'],
        [211, 'CZ072', 'Zlínský kraj'],
        [216, 'CZ080', 'Moravskoslezský kraj'],
        [223, 'CZ071', 'Olomoucký kraj'],
        [229, 'CZ053', 'Pardubický kraj'],
        [234, 'CZ052', 'Královéhradecký kraj'],
        [240, 'CZ031', 'Jihočeský kraj'],
        [248, 'CZ020', 'Středočeský kraj'],
        [261, 'CZ010', 'Hlavní město Praha'],
        [263, 'CZ051', 'Liberecký kraj'],
        [268, 'CZ042', 'Ústecký kraj'],
        [276, 'CZ041', 'Karlovarský kraj'],
        [280, 'CZ032', 'Plzeňský kraj']
    ];

    const regions = regionalStructure.filter(item => item.type === 'regional.region');
    const lastRegion = regions.pop();
    // Upravíme název (odstraníme slovo "kraj" a ořízneme mezery)
    let regionFilter = lastRegion.name.replace(/^kraj\s*/i, '').trim();

    let found = kraje.find(function(kraj) {
        let id = kraj[0];        // ID kraje
        let nazevKraje = kraj[2]; // Název kraje

        // Kontrola, jestli název kraje obsahuje hledaný text (case insensitive)
        return nazevKraje.toLowerCase().includes(regionFilter.toLowerCase());
    });

    let foundId = found ? found[0] : null;
    //console.log(foundId); // Vrátí ID nalezeného kraje nebo null, pokud nebyl nalezen
     // Pokud je nalezeno, vrátí ID, jinak null
    return foundId;
}
